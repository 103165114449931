
import { defineComponent } from "vue";
import Button from "@/components/elements/Button.vue";

export default defineComponent({
  components: {
    "lp-button": Button,
  },
  mounted() {
    this.detectBottom();
  },
  data() {
    return {
      isAtBottom: false,
    };
  },
  methods: {
    scrollToElement(): void {
      const elements = document.getElementsByClassName("scroll-to");
      for (let i = 0; i < elements.length; i++) {
        const el = elements.item(i) as Element;

        if (i === elements.length) {
          this.scrollToBottom();
          break;
        }
        if (this.isInViewport(el)) {
          el.scrollIntoView();
          break;
        }
      }
    },
    isInViewport(element: Element) {
      const rect = element.getBoundingClientRect();
      return rect.top > 20;
    },
    scrollToTop(): void {
      window.scrollTo(0, 0);
    },
    scrollToBottom(): void {
      window.scrollTo(0, document.body.scrollHeight);
    },
    detectBottom(): void {
      document.addEventListener("scroll", () => {
        let documentHeight = document.body.scrollHeight;
        let currentScroll = window.scrollY + window.innerHeight;
        let modifier = 10;
        if (currentScroll + modifier > documentHeight) {
          this.isAtBottom = true;
        } else {
          this.isAtBottom = false;
        }
      });
    },
  },
});
