
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: {
      default: "black-200",
      type: String,
      validator(value: string) {
        return ["white", "black-200", "black-400", "primary"].includes(value);
      },
    },
    size: {
      default: "small",
      type: String,
      validator(value: string) {
        return ["small", "medium", "large", "x-large"].includes(value);
      },
    },
    type: {
      default: "fill",
      type: String,
      validator(value: string) {
        return ["fill", "ghost", "outlined"].includes(value);
      },
    },
    shape: {
      default: "default",
      type: String,
      validator(value: string) {
        return ["default", "circle"].includes(value);
      },
    },
  },
});
