import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import VolunteeringView from "../views/VolunteeringView.vue";
import DonationView from "../views/DonationView.vue";
import ContactView from "../views/ContactView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsView,
    meta: {
      navbarmode: "dark",
    },
  },
  {
    path: "/volunteering",
    name: "volunteering",
    component: VolunteeringView,
    meta: {
      navbarHasBackground: true,
    },
  },
  {
    path: "/donate",
    name: "donate",
    component: DonationView,
    meta: {
      navbarmode: "dark",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: {
      navbarmode: "dark",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, behavior: "auto" };
  },
  routes,
});

export default router;
