import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["lp-button", `lp-button__type--${_ctx.type} lp-button__color--${_ctx.color} lp-button__shape--${_ctx.shape} lp-button__${_ctx.size}`])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode("Button")
    ], true)
  ], 2))
}