
import { defineComponent } from "vue";
import Button from "@/components/elements/Button.vue";
import StickyButton from "@/components/elements/ScrollButton.vue";

export default defineComponent({
  components: {
    "lp-button": Button,
    "lp-sticky-button": StickyButton,
  },
  data() {
    return {
      womenEducationProgram: {
        title: "Empowering Women Through Education",
        storyTelling: [
          "In Palenque, Colombia, young women face challenges that rob them of their potential. Limited job opportunities and a lack of resources compound their struggles. We're on a mission to change that. Our NGO was born from a deep understanding of the obstacles these women encounter – from early pregnancies to a lack of self-worth, perpetuated by cycles of abuse.",
          "Our Women's Education Program is a beacon of hope, offering practical skills and knowledge to reshape lives and redefine futures. We're committed to helping women create their own businesses from the comfort of their homes, harnessing their unique talents and aspirations. From first aid and entrepreneurship to personal finance and cultural enrichment, we're providing a holistic approach to empowerment.",
        ],
        description: {
          title: "Our Comprehensive Program",
          teachings: [
            {
              title: "First Aid Training",
              description:
                "Equipping women with life-saving skills for their families and communities.",
            },
            {
              title: "Culture and Arts",
              description:
                "Fostering creativity and self-expression, instilling pride and identity.",
            },
            {
              title: "Entrepreneurship",
              description:
                "Nurturing economic independence and innovation through business skills.",
            },
            {
              title: "Personal Finance",
              description:
                "Building financial literacy for a more secure future.",
            },
            {
              title: "Marketing And Sales",
              description:
                "Amplifying reach and influence through marketing expertise.",
            },
            {
              title: "Health",
              description:
                "Promoting healthier lifestyles through essential hygiene practices",
            },
            {
              title: "Sexual and Social Education",
              description:
                "Empowering informed decisions and prioritizing well-being.",
            },
          ],
        },
        conclusion:
          "Together, we're breaking chains and rewriting narratives. Join us in transforming lives, igniting change, and empowering women to not only create their own opportunities but also to realize their potential.",
      },
      childrenEducationProgram: {
        title: "Empowering Children for a Brighter Future",
        storyTelling: [
          "In a world where approximately 600 million children are living in extreme poverty, we are compelled by a deep sense of responsibility. These children are much more likely to become malnourished, get sick and to work in dangerous or exploitative conditions. The path to education can be filled with obstacles for them. In the midst of these struggles, our journey begins - to stand as allies, to be a source of optimism.",
          "With our education program, we aim to provide a place where knowledge is instilled, children are empowered, and the path to a brighter, more promising future is paved.",
        ],
        description: {
          title: "Our Impactful Program",
          teachings: [
            {
              title: "Basic English",
              description:
                "Beyond words, the teaching of English holds the key to undiscovered horizons. Equipping these children with a global language opens doors to opportunities beyond their current circumstances.",
            },
            {
              title: "Social Education",
              description:
                "Through the integration of education and entertainment, we delve into life's lessons that extend beyond the classroom. Guiding them through interactive experiences, we cultivate empathy, effective communication, and profound understanding.",
            },
            {
              title: "Cultural Education",
              description:
                "Through captivating forms of imagery, music, and dance, we introduce the world's diverse cultures. This cultural education enriches their perspectives, broadening horizons and nurturing a sense of unity.",
            },
          ],
        },
        conclusion:
          "Empowering these children means more than imparting knowledge – it's about forging paths toward a future where they can step into the world, equipped with the tools they need to rise above challenges.",
      },
      foodHomeForChildren: {
        title: "Food home for children",
        description: [
          "We are proud to have started the construction of a children's Community Food Home. The harsh reality is that in many impoverished villages, most children struggle to have even one meal a day, and some endure days without food altogether.",
          "Our objective is to complete the construction of this space and establish a food center that warmly welcomes children, offering more than sustenance. It's about creating a place where they can not only be fed but also find safety, the freedom to be children, a rest from the daily struggles, and the opportunity to learn essential life skills like cooking, equipping them for self-sufficiency.",
          "Though the journey is still ongoing, we've already taken significant steps. The kitchen, heart of the food home, stands as a demonstration of our commitment. While it's not yet fully finished, the project continues as we tirelessly work to turn our vision into reality.",
          "This journey isn't without its challenges, and we're well aware that it's a substantial investment in terms of resources, time, and effort. Yet, the faces of these children inspire us to go the extra mile.",
          "You have the power to make a difference too. Your support, no matter how big or small, can help us continue the journey of building this place of safety and nourishing the hopes of these young souls. Every contribution, every effort, is a step toward a brighter future for them.",
        ],
      },
      projects: ["women_education", "children_education", "food-home"],
    };
  },
  methods: {
    scrollToProgram(program: 0 | 1 | 2): void {
      const element = document.getElementById(
        this.projects[program]
      ) as Element;
      element.scrollIntoView();
    },
  },
});
