import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_lp_button = _resolveComponent("lp-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_lp_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isAtBottom ? _ctx.scrollToTop() : _ctx.scrollToElement())),
      class: "lp-button__sticky",
      color: "black-200",
      type: "ghost"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          class: _normalizeClass(_ctx.isAtBottom ? 'is-at-bottom' : ''),
          icon: "fa-solid fa-arrow-down"
        }, null, 8, ["class"])
      ]),
      _: 1
    })
  ]))
}