
import { defineComponent } from "vue";
import Button from "@/components/elements/Button.vue";
import StickyButton from "@/components/elements/ScrollButton.vue";

export default defineComponent({
  name: "AboutView",
  components: {
    "lp-button": Button,
    "lp-sticky-button": StickyButton,
  },
});
