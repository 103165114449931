
import { defineComponent } from "vue";
import Button from "@/components/elements/Button.vue";

export default defineComponent({
  components: {
    "lp-button": Button,
  },
  props: {
    image: String,
    title: String,
    description: String,
    action: String,
    url: String,
  },
  methods: {
    consoleClick() {
      console.log("Button clicked");
    },
  },
  computed: {
    imagePath() {
      return require(`@/assets/${this.image}`);
    },
  },
});
