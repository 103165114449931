import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lp-shell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_lp_footer = _resolveComponent("lp-footer")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_NavBar, {
      mode: _ctx.navBarMode,
      hasBackground: _ctx.navbarHasBackground
    }, null, 8, ["mode", "hasBackground"]),
    _createVNode(_component_router_view),
    _createVNode(_component_lp_footer)
  ]))
}