import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faSquareFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import {
  faArrowDown,
  faArrowUp,
  faBullhorn,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faGraduationCap,
  faHandsHoldingChild,
  faKitMedical,
  faLanguage,
  faLightbulb,
  faMarsAndVenus,
  faMasksTheater,
  faNotesMedical,
  faPeopleGroup,
  faSackDollar,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

library.add(
  faArrowDown,
  faArrowUp,
  faBullhorn,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faGraduationCap,
  faHandsHoldingChild,
  faInstagram,
  faKitMedical,
  faLanguage,
  faLightbulb,
  faMarsAndVenus,
  faMasksTheater,
  faNotesMedical,
  faPeopleGroup,
  faSackDollar,
  faSchool,
  faSquareFacebook
);

const app = createApp(App);

interface ScrollBindingValue {
  upClass: string;
  downClass: string;
}

app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);

export const scrollDirective = app.directive("scroll-detection", {
  mounted(el: HTMLElement, binding) {
    let lastScrollPosition = window.scrollY;

    window.addEventListener("scroll", () => {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition > lastScrollPosition) {
        // Scrolling down
        el.classList.remove((binding.value as ScrollBindingValue).upClass);
        el.classList.add((binding.value as ScrollBindingValue).downClass);
      } else if (currentScrollPosition < lastScrollPosition) {
        // Scrolling up
        el.classList.remove((binding.value as ScrollBindingValue).downClass);
        el.classList.add((binding.value as ScrollBindingValue).upClass);
      }

      if (currentScrollPosition === 0) {
        // At the top of the page
        el.classList.remove(binding.value.upClass);
        el.classList.remove(binding.value.downClass);
      }

      lastScrollPosition = currentScrollPosition;
    });
  },
});

app.mount("#app");
