
import { defineComponent } from "vue";
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";

export default defineComponent({
  components: {
    NavBar,
    "lp-footer": Footer,
  },
  data() {
    return {
      navBarMode: "light", // Set a default mode, in case no meta field is defined for the current route
      navbarHasBackground: false,
    };
  },
  watch: {
    $route: "updateNavBarConfigs", // Watch for route changes
  },
  created() {
    this.updateNavBarConfigs();
  },
  methods: {
    updateNavBarConfigs() {
      // Get the 'mode' value from the current route's meta field
      const mode = this.$route.meta.navbarmode;
      const hasBackground = this.$route.meta.navbarHasBackground;

      // Check if the 'mode' value is 'dark' or 'light', or set a default mode
      this.navBarMode = mode === "dark" ? "dark" : "light";
      this.navbarHasBackground = hasBackground === true;
    },
  },
});
