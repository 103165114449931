import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a025aad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lp-card" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("figure", null, [
      _createElementVNode("img", {
        src: _ctx.imagePath,
        alt: ""
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("article", null, _toDisplayString(_ctx.description), 1),
      _createVNode(_component_router_link, {
        to: _ctx.url ? _ctx.url : '/',
        custom: ""
      }, {
        default: _withCtx(({ navigate }) => [
          _createVNode(_component_lp_button, {
            onClick: [navigate, _ctx.consoleClick],
            color: "white"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.action), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}