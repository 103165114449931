
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    mode: {
      default: "light",
      type: String,
      validator(value: string) {
        return ["light", "dark"].includes(value);
      },
    },
    hasBackground: {
      default: false,
      type: Boolean,
    },
  },
  data: function () {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleNavbar() {
      this.expanded = !this.expanded;
    },
  },
  watch: {
    $route() {
      this.expanded = false;
    },
  },
});
