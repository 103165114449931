
import { defineComponent } from "vue";
import ArticleCard from "@/components/elements/ArticleCard.vue";
import Button from "@/components/elements/Button.vue";
import StickyButton from "@/components/elements/ScrollButton.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    ArticleCard,
    "lp-button": Button,
    "lp-sticky-button": StickyButton,
  },
  computed: {},
  data: () => ({
    volunteerCard: {
      title: "BECOME A VOLUNTEER",
      description:
        "We highly welcome and encourage people willing to make a change in others lives. Join one of our trips as a volunteer to help build communities and people in need. Get in touch with us to learn more about how you can contribute.",
      action: "Get in touch",
      url: "/contact",
    },
    donateCard: {
      title: "DONATE TO MAKE A CHANGE",
      description:
        "Your donation will not only help us extend our work to many more areas in Colombia but it will also impact to many more lives. All contributions will go fully to fund and support the work of the incredible women.",
      action: "Contribute",
      url: "/donate",
    },
    supportCard: {
      title: "SUPPORT A STARTUP BY WOMEN",
      description:
        "We work closely with the communities in need, with the main goal to empower women. They aspire to start a new life where we have the opportunity to help them flourish by investing in their gifts and talents. Join us in supporting the beginning of their new lives.",
      action: "Contribute",
      url: "/donate",
    },
  }),
  methods: {},
});
